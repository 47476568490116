import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import SprintDetails from '@/interfaces/analytics/SprintDetails';
import getSprintDetailsResponse
  from '@/graphql/userSettings/queries/getSprintDetailsResponse.graphql';
import analyticsApolloClient from '@/lib/appsync/analyticsIssues';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'sprintDetailsModule',
  store,
})
class SprintDetailsModule extends VuexModule {
//   availableSignals = getConfigEnv('SIGNALS_ARRAY');

  sprintDetails: SprintDetails = {
    start_date: 'Loading...',
    end_date: 'Loading...',
    sprint_summary: '',
  };

  @Mutation
  setSprintDetails(sprint_details): void {
    this.sprintDetails = sprint_details;
  }

  @Action
  async getSprintDetails(): Promise<void> {
    const {
      data: {
        get_current_sprint_details: currentSprintDetails,
      },
    } = await analyticsApolloClient.query({
      query: getSprintDetailsResponse,
      variables: {},
    });
    const start_date_object = new Date(currentSprintDetails.start_date);
    const end_date_object = new Date(currentSprintDetails.end_date);
    this.setSprintDetails({
      start_date: start_date_object.toDateString(),
      end_date: end_date_object.toDateString(),
      sprint_summary: currentSprintDetails.sprint_summary,
    });
  }
}

export default getModule(SprintDetailsModule);
