import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import TrackedIssue from '@/interfaces/analytics/TrackedIssue';
import getTrackedIssuesResponse from '@/graphql/userSettings/queries/getTrackedIssuesResponse.graphql';
import analyticsApolloClient from '@/lib/appsync/analyticsIssues';
import submitJiraTicket from '@/graphql/userSettings/mutations/submitJiraTicket.graphql';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'userTrackedIssuesModule',
  store,
})
class UserTrackedIssuesModule extends VuexModule {
//   availableSignals = getConfigEnv('SIGNALS_ARRAY');

  userIssues: TrackedIssue[] = [];

  @Mutation
  resetIssues(): void {
    this.userIssues = [];
  }

  @Mutation
  addIssue(issue): void {
    this.userIssues.push(issue);
  }

  @Mutation
  addTrackedIssue(issue): void {
    this.userIssues.push(issue);
  }

  @Action
  async postNewIssue({
    username,
    description,
    ticket_title,
    page,
    issue_type,
  }) {
    try {
      const {
        data: {
          submit_jira_ticket: {
            id: jiraTicketID,
          },
        },
      } = await analyticsApolloClient.mutate({
        mutation: submitJiraTicket,
        variables: {
          input: {
			  description,
            username,
            ticket_title,
			  operator: getConfigEnv('OPERATOR_LOWERCASED'),
			  page,
			  issue_type,

          },
        },
      });
    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }

  @Action
  async getIssues(): Promise<void> {
    this.resetIssues();
    const {
      data: {
        get_tracked_issues: trackedIssuesData,
      },
    } = await analyticsApolloClient.query({
      query: getTrackedIssuesResponse,
      variables: {
		  input: {
          operator: getConfigEnv('OPERATOR_PASCALED'),
		  },
      },
    });

    const parsed_results = jsonParse(trackedIssuesData.issues_dict);
    for (const key in parsed_results) {
      let issue_type = 'Bug';
      if (key == 'performance_tickets') {
        issue_type = 'Performance';
      } else if (key == 'data_issue_tickets') {
        issue_type = 'Data issue';
      } else if (key == 'functionality_tickets') {
        issue_type = 'Functionality';
      }
      for (let y = 0; y < parsed_results[key].length; y++) {
        const issue_object: TrackedIssue = {
          id: parsed_results[key][y].id,
          issue_type,
          fields: {
            status: {
              name: parsed_results[key][y].fields.status.name,
            },
            description: parsed_results[key][y].fields.description,
            summary: parsed_results[key][y].fields.summary,
          },

        };
        this.addTrackedIssue(issue_object);
      }
    }
  }
}

export default getModule(UserTrackedIssuesModule);
