import accountModule from '@/store/modules/accountModule';

const getNameByEmail = (email) => accountModule.reassignmentList?.find(
  (user) => user.email === email,
)?.name || email;

export {
  // eslint-disable-next-line import/prefer-default-export
  getNameByEmail,
};
